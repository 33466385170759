import React from 'react';
import { IoWater } from "react-icons/io5";
import {FaHeart} from "react-icons/fa";

const ProgressBar = (props) => {
        return (
            <div className="progress md-progress" >
                <div className="progress-bar"
                    style={{ width: (props.pv * 100 / props.pvMax) + "%" }}
                    aria-valuenow={props.pv}
                    aria-valuemin="0"
                    aria-valuemax={props.pvMax}
                    role="progressbar" >
                    <i className={` fas ${props.faType} ${props.bgType} icon-text`}> {props.barName === ' : pv ' ? <FaHeart size={14}/> : <IoWater size={15}/>}{props.pv} </i>
                </div>
            </div >
        )
}

export default ProgressBar;